import React, { useCallback } from "react";
import "./App.css";
import { Applause } from "./components/Applause";
import { ClapButton } from "./components/ClapButton";
import { useClapStore } from "./hooks/useClapStore";
import { useDecreasingVolume } from "./hooks/useDecreasingVolume";
import { VersionNumber } from "./components/VersionNumber";
import { useRoomName } from "./hooks/useRoomName";
import { ConnectionStatus } from "./components/ConnectionStatus";
import { Helmet } from "react-helmet";
import { ShareButton } from "./components/ShareButton";
import { ServiceWorkerRegistration } from "./components/ServiceWorkerRegistration";
import { ConnectedUsers } from "./components/ConnectedUsers";

function App() {
  const { volume, setVolume } = useDecreasingVolume(0.2, 100);
  const { roomName, roomDisplayName } = useRoomName();

  const onClap = useCallback(
    () => setVolume((v) => Math.min(1.5, v + 0.1)),
    [setVolume]
  );
  const { sendClap, connected, roomMembers } = useClapStore(roomName, onClap);
  const buttonScale = volume * 3 + 1;

  return (
    <>
      <Helmet>{roomName && <title>Tap Clap ({roomDisplayName})</title>}</Helmet>
      <ServiceWorkerRegistration />
      <div className="App">
        <h1 className="title">Tap to Clap!</h1>
        <Applause type="single" volume={volume} />
        <Applause type="crowd" volume={volume - 0.2} />
        <ClapButton scale={buttonScale} onClick={sendClap} />
        <ShareButton roomName={roomName} />
        <VersionNumber />
        <ConnectionStatus connected={connected} roomName={roomDisplayName} />
        <ConnectedUsers roomMembers={roomMembers} />
      </div>
    </>
  );
}

export default App;
