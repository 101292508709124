import React, { FC } from 'react';
import './ConnectionStatus.css';

type Props = {
  connected: boolean;
  roomName: string | null;
};
export const ConnectionStatus: FC<Props> = ({ connected, roomName }) => (
  <div className="ConnectionStatus">
    <div className={`state-dot ${connected && 'connected'}`} />
    {connected ? (
      <>
        <a href={window.location.href}>{roomName}</a>
      </>
    ) : (
      'Connecting...'
    )}
  </div>
);
