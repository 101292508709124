import React, { FC } from 'react';
import './ClapButton.css';

type Props = {
  scale: number;
  onClick(): void;
};

export const ClapButton: FC<Props> = ({ scale, onClick }) => (
  <button
    className="ClapButton"
    style={{
      transform: `scale(${scale})`,
    }}
    onClick={onClick}
  >
    <span role="img" aria-label="clap">
      👏
    </span>
  </button>
);
