import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { firebaseConfig } from '../firebaseConfig';
import { useDeviceId } from './useDeviceId';

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export const useClapStore = (roomName: string | null, onClap: () => void) => {
  const [connected, setConnected] = useState(false);
  const [roomMembers, setRoomMembers] = useState(0);
  const { deviceId } = useDeviceId();

  useEffect(() => {
    if (!roomName) return;

    let unsubscribe: () => void;

    async function subscribe() {
      let clapCount = 0;
      let receivedFirstSnapshot = true;

      await db
        .collection('rooms')
        .doc(roomName!)
        .set(
          {
            members: firebase.firestore.FieldValue.arrayUnion(deviceId),
            lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );

      setConnected(true);

      unsubscribe = db
        .collection('rooms')
        .doc(roomName!)
        .onSnapshot((doc) => {
          const data = doc.data();

          setRoomMembers(data?.members?.length || 0);

          if (receivedFirstSnapshot) {
            receivedFirstSnapshot = false;
            return;
          }

          if (!data) return;
          if (data.clapCount === clapCount) return;

          clapCount = data.clapCount;
          onClap();
        });
    }

    subscribe();

    return () => {
      unsubscribe && unsubscribe();
    };
  }, [roomName, onClap, setRoomMembers, deviceId]);

  const sendClap = useCallback(() => {
    if (!roomName) return;

    return db
      .collection('rooms')
      .doc(roomName)
      .set(
        {
          clapCount: firebase.firestore.FieldValue.increment(1),
          lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
  }, [roomName]);

  useEffect(() => {
    if (!roomName) return;
  }, [roomName, deviceId]);

  return { sendClap, connected, roomMembers };
};
