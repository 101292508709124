import React, { FC, useCallback, useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Alert } from './Alert';
import * as serviceWorker from '../serviceWorker';

export const ServiceWorkerRegistration: FC = () => {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
    null
  );

  useEffect(() => {
    console.log('Registering service worker');
    serviceWorker.register({
      onUpdate: (registration) => {
        console.log('Update available');
        setWaitingWorker(registration.waiting);
      },
      onSuccess: () => {
        console.log('Service worker ready');
      },
    });
  }, []);

  const [updating, setUpdating] = useState(false);

  const update = useCallback(() => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setUpdating(true);
    window.location.reload();
  }, [waitingWorker]);

  const showReloadMessage = !!waitingWorker;
  if (!showReloadMessage) return null;

  return (
    <Alert
      title="New update available"
      action={
        <button onClick={update}>{updating ? <FaCheck /> : 'Get it'}</button>
      }
    >
      Do you like new stuff?
    </Alert>
  );
};
