import { useState, useEffect, useMemo } from "react";

let roomNameFromUrl = document.location.pathname;
if (roomNameFromUrl.startsWith("/"))
  roomNameFromUrl = roomNameFromUrl.substring(1);

export function useRoomName() {
  const [roomName, setRoomName] = useState(roomNameFromUrl);

  useEffect(() => {
    if (!!roomName) return;

    createNewRoom().then(setRoomName);
  }, [roomName]);

  const roomDisplayName = useMemo(() => {
    try {
      const decodedRoomName = atob(roomName);
      new URL(decodedRoomName);
      //if we successfully decoded the roomname AND it's a well-formed URL then it probably came from the
      //chrome extension
      return `Watching ${decodedRoomName}`;
    } catch {
      return roomName;
    }
  }, [roomName]);

  return { roomName, roomDisplayName };
}

async function createNewRoom() {
  const roomName = await generateRoomName();
  const newLocation = new URL(document.location.href);
  newLocation.pathname = roomName;
  window.history.replaceState(
    { roomName },
    `${roomName} | Tap Clap`,
    newLocation.href
  );
  return roomName;
}

async function generateRoomName() {
  return `room-${randomNumberString()}-${randomNumberString()}`;
}

function randomNumberString(length = 3) {
  return Math.floor(Math.random() * 999)
    .toString()
    .padStart(length, "0");
}
