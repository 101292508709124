import { FC } from "react";
import React from "react";
import { FaShareAlt } from "react-icons/fa";
import "./ShareButton.css";

type Props = {
  roomName: string | null;
};

export const ShareButton: FC<Props> = ({ roomName }) => {
  if (!window.navigator.share) return null;

  async function share() {
    if (!roomName || !window.navigator.share) return;

    await window.navigator.share({
      title: `Tap Clap (${roomName})`,
      text: `Put your hands together!`,
      url: window.location.href,
    });
  }

  const canShare = !!roomName;

  return (
    <button disabled={!canShare} className="ShareButton" onClick={share}>
      <FaShareAlt />
      &nbsp;Start a Crowd
    </button>
  );
};
