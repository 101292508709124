import { FC, ReactNode } from 'react';
import React from 'react';
import './Alert.css';
import { FaInfoCircle } from 'react-icons/fa';

type Props = {
  title: ReactNode;
  icon?: ReactNode;
  action?: ReactNode;
};

export const Alert: FC<Props> = (props) => {
  const { icon = <FaInfoCircle />, title, action, children } = props;
  return (
    <div className="Alert">
      <div className="icon">{icon}</div>
      <h6 className="title">{title}</h6>
      <div className="content">{children}</div>
      {action && <div className="action">{action}</div>}
    </div>
  );
};
