import { FC } from 'react';
import { FaUser, FaUsers } from 'react-icons/fa';
import React from 'react';
import './ConnectedUsers.css';

type Props = {
  roomMembers: number;
};

export const ConnectedUsers: FC<Props> = ({ roomMembers }) => {
  if (!roomMembers) return null;

  if (roomMembers === 1)
    return (
      <div className="ConnectedUsers">
        I'm clapping on my own and that's fine &nbsp;
        <FaUser />
      </div>
    );

  return (
    <div className="ConnectedUsers">
      In a crowd of&nbsp;<strong>{roomMembers}</strong> &nbsp;
      <FaUsers />
    </div>
  );
};
