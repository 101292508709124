import React from "react";
import { version } from "../../package.json";

export const VersionNumber = () => (
  <div
    style={{
      position: "fixed",
      top: 10,
      right: 10,
      fontSize: "var(--label-size)",
      color: "var(--label-text)",
    }}
  >
    v{version}
  </div>
);
